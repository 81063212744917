import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { AuthProvider } from "./services/Auth";
import Login from "./pages/login/Login";
import { AuthProvider, Private } from "./services/Auth";
import Home from "./pages/home/Home";

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" >
            <Route index element={<Private><Home /></Private>} />
            <Route path="Login" element={<Login />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
