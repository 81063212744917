import csvDownload from "json-to-csv-export";

export async function downloadReport(token) {
  const response = await fetch(
    process.env.REACT_APP_RELAT + "/api/PlataformaRelatorios/RelatorioTechSP",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Erro de requisição: ${response.status}`);
  }

  const res = await response.json();

  if(!res.lenght) return alert("Não existem alunos cadastrados para o telecentro.")

  var dataAtual = new Date();
  var dia = dataAtual.getDate();
  var mes = dataAtual.getMonth() + 1;
  var ano = dataAtual.getFullYear();
  var hora = dataAtual.getHours();
  var minuto = dataAtual.getMinutes();
  var segundo = dataAtual.getSeconds();
  var dataFormatada = `${ano}-${mes}-${dia}-${hora}-${minuto}-${segundo}`;

  return csvDownload({
    data: res,
    filename: "Relatorio " + dataFormatada,
  });
}
