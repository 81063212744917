import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            <Link color="inherit" href="https://recode.org.br/">
                RECODE
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}