import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../services/Auth";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "../../components/Copyright/Copyright";
import Logo from "../../images/logo-horiz-seq1-V2.gif";

export default function Login() {
  const { login, authenticated, form, setForm } = useContext(AuthContext);

  if (authenticated) return <Navigate to="/" />;

  const projetos = [
    {
      nome: "TechSP",
      diasabled: false,
    },
    {
      nome: "BR",
      diasabled: true,
    },
  ];

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="logo" />
          
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Acessar
          </Typography>

          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              login(form);
            }}
          >
            <TextField
              sx={{ my: 0.5 }}
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              type="email"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />

            <TextField
              sx={{ my: 0.5 }}
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              autoComplete="current-password"
              value={form.senha}
              onChange={(e) => setForm({ ...form, senha: e.target.value })}
            />

            <FormControl sx={{ my: 0.5 }} fullWidth>
              <InputLabel>Projeto</InputLabel>
              <Select
                required
                label="Projeto"
                onChange={(e) => setForm({ ...form, projeto: e.target.value })}
                defaultValue=""
              >
                {projetos.map((projeto) => (
                  <MenuItem
                    key={projeto.nome}
                    value={projeto.nome}
                    disabled={projeto.diasabled}
                  >
                    {projeto.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 1 }}
            >
              Entrar
            </Button>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
