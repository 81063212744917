import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
import DownloadIcon from "@mui/icons-material/Download";
import { AuthContext } from "../../services/Auth";
import { downloadReport } from "../../services/downloadReport";

export default function Home() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <ResponsiveAppBar />
      <Container sx={{ padding: 2 }}>
        <Typography variant="body1" component="h2" py={3}>
          Bem vindo(a): {user.nome}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Relatórios</TableCell>
                <TableCell align="right">Download</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>Alunos cadastrados</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    onClick={() => downloadReport(user.token)}
                  >
                    <DownloadIcon />
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
