import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Toast } from "./swal";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const [form, setForm] = useState({
    email: "",
    senha: "",
    projeto: "",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoveredUser = sessionStorage.getItem("user");

    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }

    setLoading(false);
  }, []);

  async function login({ email, senha, projeto }) {
    const body = {
      email: email,
      senha: senha,
      projeto: projeto,
    };

    await fetch(
      process.env.REACT_APP_RELAT + "/api/PlataformaRelatorios/Login",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const logedUser = result.payload;
          sessionStorage.setItem("user", JSON.stringify(logedUser));
          setUser(logedUser);
          navigate("/");
          return;
        }

        // alert(result.message);
        Toast.fire({
          icon: "error",
          title: `Não foi possível entrar. \r\n Erro: ${result.message}`,
        });

        // setForm({
        //   email: "",
        //   senha: "",
        //   projeto: "",
        // });
        return;
      })
      .catch((error) => {
        console.error("erro: ", error);
        alert(error.message);
      });
  }

  function logout() {
    sessionStorage.removeItem("user");
    setUser(null);
    navigate("/login");
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user, //checando se é um falsY no objeto
        user, //State
        loading, //State
        login,
        logout,
        form, // loginForm
        setForm, // loginForm
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function Private({ children }) {
  const { authenticated, loading } = useContext(AuthContext);
  if (loading) return "Carregando...";
  if (!authenticated) return <Navigate to="/login" />;
  return children;
}
